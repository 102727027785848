
import { GetNotice as NoticeList } from "@/Api/Basics";
import Storage from "@/util/Storage"
import {deleteNoticeAdmin} from "@/Api/Platform/bascs";
import {Toast} from "vant";

// 请求列表
export function getNoticeList(body:any = {}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    return Promise.resolve(NoticeList({ userId,token },body).then(res=>{
        return res.data.list || []
    }))
}



// 请求列表
export function delNotice(id:string|number = "") {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    Toast.loading({ duration:0,message:"删除中~~~" })
    return Promise.resolve(deleteNoticeAdmin({ userId,token },{ id,isPlatform: 1 }).then(res=>{
        Toast.clear()
        return res.data
    }))
}
